import { useQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getAMPM, getHourText, globalMilliseconds } from "../../global";
import { SEE_BOOKING_CALENDAR_QUERY } from "../../graphqls/queries";
import { useBookingHeaderHeight } from "../../hooks/useBookingHeaderHeight";
import { useBookingHourWidth } from "../../hooks/useBookingHourWidth";
import { useBookingWidth } from "../../hooks/useBookingWidth";
import { useNavigationWidth } from "../../hooks/useNavigationWidth";
import {
    seeBookingCalendar,
    seeBookingCalendarVariables,
    seeBookingCalendar_seeBookingCalendar_bookings,
} from "../../__generated__/seeBookingCalendar";
import { HEADER_HEIGHT } from "../Header";
import { IProduct } from "./Bookings";
import {
    BookingsCallendarDayProduct,
    isNow,
} from "./BookingsCalendarDayProduct";

export const MARGIN_LEFT_NUMBER = 10;

export const MARGIN_LEFT = `${MARGIN_LEFT_NUMBER}px`;
export const BOOKING_HEIGHT = "65px";
export const PRODUCT_TITLE_HEIGHT = "35px";
export const PRODUCT_TITLE_BORDER = "2px";
export const SCROLLBAR_WIDTH = 8;
export const SCROLLBAR_HEIGHT = 8;

const Container = styled.div<{ navigationWidth: string }>`
    display: flex;
    flex-direction: column;
    margin-top: -1px;
    margin-left: ${MARGIN_LEFT};
    min-width: calc(
        100vw - ${(props) => props.navigationWidth} - ${MARGIN_LEFT} -
            ${SCROLLBAR_WIDTH}px
    );
    border-top: 1px solid black;
`;

const TableScroll = styled.div<{
    navigationWidth: string;
}>`
    overflow-x: overlay;
    overflow-y: hidden;
    max-width: calc(
        100vw - ${(props) => props.navigationWidth} - ${SCROLLBAR_WIDTH}px
    );

    &::-webkit-scrollbar {
        width: ${SCROLLBAR_WIDTH}px;
        height: ${SCROLLBAR_HEIGHT}px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: ${SCROLLBAR_WIDTH}px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.3);
    }
`;

const ContentScroll = styled.div<{
    productWidth: string;
    navigationWidth: string;
    bookingHeaderHeight: string;
}>`
    overflow-x: hidden;
    overflow-y: overlay;
    min-width: ${(props) => props.productWidth};
    max-height: calc(
        100vh - ${HEADER_HEIGHT} - ${(props) => props.bookingHeaderHeight} -
            ${PRODUCT_TITLE_HEIGHT} - ${PRODUCT_TITLE_BORDER} -
            ${SCROLLBAR_HEIGHT}px
    );

    &::-webkit-scrollbar {
        width: ${SCROLLBAR_WIDTH}px;
        height: ${SCROLLBAR_HEIGHT}px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: ${SCROLLBAR_WIDTH}px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.3);
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
`;

const ProductContainer = styled.div`
    display: flex;
`;

const HeaderBorder = styled.div`
    height: 1px;
    margin-top: -1px;
    width: 100%;
    border-top: 1px solid ${(props) => props.theme.border};
`;

const ProductTitle = styled.div<{ bookingWidth: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 800;
    height: ${PRODUCT_TITLE_HEIGHT};
    width: ${(props) => props.bookingWidth};
    min-width: ${(props) => props.bookingWidth};
    border-right: 1px solid ${(props) => props.theme.borderLight};
    border-bottom: 1px solid ${(props) => props.theme.border};

    @media ${({ theme }) => theme.mobile} {
        margin-right: -0.5px;
    }
`;

const ProductText = styled.span`
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px 5px;
`;

const Content = styled.div`
    display: flex;
`;

const HourTable = styled.div<{ hourWidth: string }>`
    display: flex;
    flex-direction: column;
    width: ${(props) => props.hourWidth};
    min-width: ${(props) => props.hourWidth};
    border-right: 1px solid ${(props) => props.theme.borderLight};
    margin-right: -0.5px;
    font-size: 12px;
`;

const TimeContainer = styled.div<{ now: boolean }>`
    display: flex;
    height: ${BOOKING_HEIGHT};
    border-bottom: 0.5px solid ${(props) => props.theme.borderLight};
    background-color: ${(props) =>
        props.now ? props.theme.lightBeige : props.theme.white};
`;

const TimeTextLabel = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 90%;

    @media ${({ theme }) => theme.mobile} {
        flex-direction: column;
        align-items: center;
        margin: 10px;
        width: 100%;
    }
`;

const TimeText = styled.span`
    color: ${(props) => props.theme.fontDarkGray};
`;

interface IBookingsCalendarDayProps {
    franchiseIndex: number;
    franchiseName: string;
    useHalfTimeFranchise: boolean;
    products: IProduct[];
    date: Date;
}

export function BookingsCalendarDay({
    franchiseIndex,
    franchiseName,
    useHalfTimeFranchise,
    products,
    date,
}: IBookingsCalendarDayProps) {
    const navigationWidth = useNavigationWidth();
    const bookingWidth = useBookingWidth();
    const bookingHourWidth = useBookingHourWidth();
    const bookingHeaderHeight = useBookingHeaderHeight();

    const [bookings, setBookings] = useState<
        seeBookingCalendar_seeBookingCalendar_bookings[]
    >([]);

    const startDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0,
        0,
        0,
        globalMilliseconds(),
    );

    const endDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59,
        59,
        globalMilliseconds(),
    );

    useQuery<seeBookingCalendar, seeBookingCalendarVariables>(
        SEE_BOOKING_CALENDAR_QUERY,
        {
            variables: {
                startDateTime: `${startDate.getTime()}`,
                endDateTime: `${endDate.getTime()}`,
                franchiseIndex,
            },
            onCompleted: (data: seeBookingCalendar) => {
                const {
                    seeBookingCalendar: { ok, error, bookings: seeBookings },
                } = data;

                let bookingsTemp: seeBookingCalendar_seeBookingCalendar_bookings[] =
                    [];

                if (ok) {
                    setBookings([]);
                    seeBookings?.map((booking) => {
                        if (booking) {
                            bookingsTemp.push(booking);
                        }
                    });

                    setBookings(bookingsTemp);
                } else {
                    alert(error);
                }
            },
            onError: (err) => {
                console.log(`SEE_BOOKING_CALENDAR_QUERY error: ${err}`);
            },
        },
    );

    const productWidth = `${
        bookingWidth * products.length + bookingHourWidth + MARGIN_LEFT_NUMBER
    }px`;

    return (
        <Container navigationWidth={navigationWidth}>
            <TableScroll navigationWidth={navigationWidth}>
                <Header>
                    <ProductContainer>
                        <ProductTitle
                            bookingWidth={`${bookingWidth}px`}
                            style={{
                                width: bookingHourWidth,
                                minWidth: bookingHourWidth,
                            }}
                        ></ProductTitle>
                        {products.map((product, index) => {
                            return (
                                <ProductTitle
                                    key={index}
                                    bookingWidth={`${bookingWidth}px`}
                                >
                                    <ProductText>{product.name}</ProductText>
                                </ProductTitle>
                            );
                        })}
                    </ProductContainer>
                    <HeaderBorder />
                </Header>
                <ContentScroll
                    productWidth={productWidth}
                    navigationWidth={navigationWidth}
                    bookingHeaderHeight={bookingHeaderHeight}
                >
                    <Content>
                        <BookingsCalendarTimeTable
                            useHalfTimeFranchise={useHalfTimeFranchise}
                            currentDate={date}
                        />

                        <BookingsCallendarDayProduct
                            bookings={bookings}
                            products={products}
                            date={date}
                            width={`${bookingWidth}px`}
                            height={BOOKING_HEIGHT}
                            franchiseIndex={franchiseIndex}
                            franchiseName={franchiseName}
                            useHalfTimeFranchise={useHalfTimeFranchise}
                        />
                    </Content>
                </ContentScroll>
            </TableScroll>
        </Container>
    );
}

export const get24Dates = (useHalfTime: boolean, baseDate?: Date) => {
    const dates: Date[] = [];

    for (let i = 0; i <= 23; ++i) {
        let date = new Date();
        if (baseDate) {
            date = new Date(baseDate.getTime());
        }

        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        date.setHours(i);
        dates.push(date);

        if (useHalfTime) {
            let date = new Date();
            if (baseDate) {
                date = new Date(baseDate.getTime());
            }
            date.setMinutes(30);
            date.setSeconds(0);
            date.setMilliseconds(0);
            date.setHours(i);
            dates.push(date);
        }
    }

    return dates;
};

interface IBookingsCalendarTimeTableProps {
    useHalfTimeFranchise: boolean;
    currentDate?: Date;
}

const BookingsCalendarTimeTable = ({
    useHalfTimeFranchise,
    currentDate,
}: IBookingsCalendarTimeTableProps) => {
    const dates = get24Dates(useHalfTimeFranchise, currentDate);
    const bookingHourWidth = useBookingHourWidth();
    const nowDateRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (nowDateRef) {
            nowDateRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [nowDateRef, dates]);

    return (
        <HourTable hourWidth={`${bookingHourWidth}px`}>
            {dates.map((date, index) => {
                const findNowDateRef = () => {
                    let find = false;

                    if (nowDateRef && currentDate) {
                        const newDate = new Date(date.getTime());
                        newDate.setFullYear(currentDate.getFullYear());
                        newDate.setMonth(currentDate.getMonth());
                        newDate.setDate(currentDate.getDate());

                        if (isNow(newDate, true)) {
                            find = true;
                        }

                        if (find === true) {
                            if (index - 1 >= 0) {
                                const beforeDate = dates[index - 1];
                                const newDate = new Date(beforeDate.getTime());
                                newDate.setFullYear(currentDate.getFullYear());
                                newDate.setMonth(currentDate.getMonth());
                                newDate.setDate(currentDate.getDate());
                                if (isNow(newDate, true)) {
                                    find = false;
                                }
                            }
                        }
                    }

                    return find;
                };

                return (
                    <TimeContainer
                        key={index}
                        now={isNow(date, false)}
                        ref={findNowDateRef() ? nowDateRef : null}
                    >
                        <TimeTextLabel>
                            <TimeText>{getAMPM(date)}</TimeText>
                            <TimeText>{getHourText(date)}</TimeText>
                        </TimeTextLabel>
                    </TimeContainer>
                );
            })}
        </HourTable>
    );
};
