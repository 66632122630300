import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { useSystem } from "../../hooks/useMember";
import { ButtonSpan, lightTheme } from "../../styles";
import { InfoItemGrid } from "./InfoItemGrid";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${(props) => props.theme.background};
    padding-top: 20px;
    padding-bottom: 40px;
`;

const Text = styled.span`
    font-size: 20px;
`;

const TextBlue = styled(Text)`
    color: ${(props) => props.theme.lightBlue};
`;

const ButtonContainer = styled.div`
    margin-top: 20px;
`;

const Button = styled(ButtonSpan)`
    color: ${(props) => props.theme.red};
`;

export function InfoItems() {
    const system = useSystem();
    const isTablet = useMediaQuery({ query: lightTheme.tablet });

    return (
        <Container>
            <Text>여러개의 장소를 운영해도 문제 없습니다!</Text>
            <Text style={{ marginTop: "20px" }}>
                <TextBlue>14일간 무료 체험</TextBlue>해 보시고 결정하세요
            </Text>
            {/* <InfoItemGrid /> */}
            {/* {isTablet ? (
                <ButtonContainer>
                    <Button onClick={() => openUrlNewTab(routes.saleItems)}>
                        이곳을 눌러 이용 요금 및 부가서비스 확인
                    </Button>
                </ButtonContainer>
            ) : (
                <InfoItemGrid />
            )} */}
        </Container>
    );
}
